.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

/*mark sheet border*/

.bgColor {
  background-color: #ffffee;
}

.borderGradient { 
  border: 8px solid;
  padding: 10px;
  border-image: repeating-linear-gradient(to bottom right, #33475b, #0033CC, #FF77CC, rgb(255, 122, 89)) 20;
}

.borderSolid {
  border: 8px groove #e28743;
  padding: 10px;

}

.colorname {
  border: 6px groove  #e28743;
}


path {
  fill: transparent;
}

text {
  fill: #FF9800;
}

/*background iamge*/

.demo-wrap {
  overflow: hidden;
  position: relative;
}

.demo-bg {
  opacity: 0.1;
  position: absolute;
  left: 0;
  top: 100;
  width: 100%;
  height: auto;
  padding-top:300px;
}

/*layout */
.layout {
  color: var(--txt-color);
}

.layout__content {
  padding-left: 100px;
  background-color: var(--second-bg);
  min-height: 100vh;
}

.layout__content-main {
  padding-left: var(--sidebar-width);
  background-color: var(--second-bg);
  min-height: 100vh;}

  .layout__content-full {
    padding: 0px;
    background-color: var(--second-bg);
    min-height: 100vh;
  }
  

/*layout*/

/*sidebar*/
.sidebar {
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  z-index: 9999;
}

.sidebar__logo {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar__logo > img {
  height: 45px;
}

.sidebar__item {
  padding: 0 20px;
}

.sidebar__item-inner {
  padding: 15px 25px;
  display: flex;
  align-items: center;
  font-weight: 600;
  transition: color 0.3s ease 0s;
}

.sidebar__item-inner > i {
  margin-right: 10px;
  font-size: 1.5rem;
}

.sidebar__item-inner > span {
  text-transform: capitalize;
}

.sidebar__item-inner:hover {
  color: var(--main-color);
}

.sidebar__item-inner.active {
  border-radius: var(--border-radius);
  background-image: linear-gradient(
      to right,
      var(--main-color),
      var(--second-color)
  );
  color: var(--txt-white);
}

.sidebar-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-btn-wrapper .sidebar-btn {
  padding: 1px 15px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.05);
  color: #adadad;
  text-decoration: none;
  margin: 0 auto;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sidebar-btn-wrapper .sidebar-btn span {
  margin-left: 5px;
  font-size: 13px;
}
.sidebar-btn-wrapper .sidebar-btn:hover {
  color: gold;
}

  .collapsed .sidebar-btn {
    display: inline-block;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 40px;
    padding: 0;
 }
/*sidebar*/


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




@media (max-width:600px) {
  .dashboard-sec h1{
    font-size: 20px;
    }
    
    .dashboard-sec .dash-logo{
      width:50px;
    }
.theme-sec .nav-theme-icon{
  font-size: 24px;
}
.pro-sidebar.md.toggled .overlay {
display: none !important;
}


}