 .app .sidebar-btn-wrapper {
	 display: flex;
	 align-items: center;
	 justify-content: center;
}
 .app .sidebar-btn-wrapper .sidebar-btn {
	 padding: 1px 15px;
	 border-radius: 40px;
	 background: rgba(255, 255, 255, 0.05);
	 color: #adadad;
	 text-decoration: none;
	 margin: 0 auto;
	 height: 35px;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 text-overflow: ellipsis;
	 overflow: hidden;
}
 .app .sidebar-btn-wrapper .sidebar-btn span {
	 margin-left: 5px;
	 font-size: 13px;
}
 .app .sidebar-btn-wrapper .sidebar-btn:hover {
	 color: gold;
}
 .app .collapsed .sidebar-btn {
	 display: inline-block;
	 border-radius: 50%;
	 width: 35px;
	 height: 35px;
	 line-height: 40px;
	 padding: 0;
}
 .badge {
	 padding: 3px 6px;
	 font-size: 9px;
	 letter-spacing: 1px;
	 border-radius: 15px;
}
 .badge.red {
	 color: #fff;
	 background: #d63030;
}
 .badge.gray {
	 color: #fff;
	 background: #777;
}
 .badge.yellow {
	 color: #000;
	 background: #ffd331;
}

/*table*/

.table-wrapper {
    overflow-y: auto;
}

.table {
    width: 100%;
    min-width: 400px;
    border-spacing: 0;
}

.theadStyle {
    background-color: var(--second-bg);
	color: var(--txt-color);
}

.tbodyStyle {
	color: var(--txt-color);
}

.tbodyStyle .trStyle:hover {
    background-color: var(--main-color);
    color: var(--txt-white);
}

.table__pagination {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
}

.table__pagination-item ~ .table__pagination-item {
    margin-left: 10px;
}

.table__pagination-item {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.table__pagination-item.active,
.table__pagination-item.active:hover {
    background-color: var(--main-color);
    color: var(--txt-white);
    font-weight: 600;
}

.table__pagination-item:hover {
    color: var(--txt-white);
    background-color: var(--second-color);
}

/*table*/

/*button*/
.buttonStyle {
	background-color: var(--main-color);
}
/*button*/

/*modal*/
.modalStyle {
	color: var(--txt-color);
}
/*modal*/

/*column grid*/
.grid-container {
	display: inline-grid;
	width: 300px;
	grid-template-columns: 300px 400% 200%;
  }

  
 